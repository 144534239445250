import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Hero from '../components/hero';
import Body from '../components/body';
import DetailPanel from '../components/detail/panel';
import { ContentContainer } from '../components/detail/detail.stc';
import { graphql } from 'gatsby'

export const servicesPageData = graphql`
  query servicesPageQuery {
    sanityServices {
      hero {
        title
        subtitle
        image {
          alt
          asset {
            fluid(maxWidth: 1920, maxHeight: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      headline
      subhead
      accent {
        asset {
          fluid(maxWidth: 1920, maxHeight: 750) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawCopy
      serviceList {
        slug {
          current
        }
        title
        subtitle
        id
        hero {
          image {
            asset {
              fluid(maxWidth: 1920, maxHeight: 750) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        _rawSummary
      }
      seo {
        meta_description
        seo_title
      }
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const ServicesPage = (props) => {
  const { data } = props;

  const pageData = data.sanityServices;
  const serviceList = pageData.serviceList;
  const seoMeta = pageData.seo;
  const openGraph = pageData.openGraph;

  return (
    <Layout headerLayout={2}>
    <SEO
      title={seoMeta?.seo_title}
      ogtitle={openGraph?.title}
      description={seoMeta?.meta_description}
      ogdescription={openGraph?.description}
      image={openGraph?.image?.asset?.fluid}
      pathname={props.location.pathname}
    />
      <Hero
        title={pageData.hero.title}
        subhead={pageData.hero.subtitle}
        image={pageData.hero.image.asset.fluid}
        alt={pageData.hero.image.alt}
      />
      <Body
        title={pageData.headline}
        subhead={pageData.subhead}
        copy={pageData._rawCopy}
        image={pageData.accent.asset.fluid}
        titleAlign={'left'}
      />
      <ContentContainer>
        {serviceList.map((data, i) => {
          return (
            <DetailPanel
              key={i}
              anchor={data.id}
              title={data.title}
              subtitle={data.subtitle}
              copy={data._rawSummary}
              image={data.hero.image.asset.fluid}
              alt={data.title}
              ctaText={'Learn More'}
              ctaPath={data.slug.current}
            />
          );
        })}
      </ContentContainer>
    </Layout>
  );
};

export default ServicesPage;
